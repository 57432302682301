import { Component, OnInit } from '@angular/core';
import { ConfigService } from '@core/services/config.service';
import { AuthService } from '@core/services/auth.service';
import { IUserInfo } from '@src/app/core/auth/auth.model';
import { ThemeKey, ThemeNamesEnum } from '@src/app/core/config/const/theme.colors';
import { ThemesService } from '@src/app/core/services/themes.service';

@Component({
  selector: 'app-error-404',
  templateUrl: './error-404.component.html',
  styleUrls: ['./error-404.component.scss'],
})
export class Error404Component implements OnInit {
  public user: IUserInfo;
  public readonly themeNames = ThemeNamesEnum;
  public currentTheme: ThemeKey = ThemeNamesEnum.light;

  constructor(
    private config: ConfigService,
    private authService: AuthService,
    private themeService: ThemesService
  ) {
    this.user = this.authService.authenticated()?.getValue() || null;

    if (!this.user) {
      this.config.setSettings({
        layout: {
          navigation: 'none',
          toolbar: 'none',
          footer: 'none',
        },
      });
    }
  }

  ngOnInit(): void {
    this.currentTheme = this.themeService.getCurrentTheme();
  }
}
