@if (!user) {
  <div class="full-page-container" fxLayout="column" appPerfectScrollbar>
    <div class="page-container">
      <div class="logo">
        @if (currentTheme === themeNames.light) {
          <img src="assets/images/logos/AnyManager/Black/AnyManager.svg" width="206" height="40" alt="anymanager-logo" />
        }
        @if (currentTheme === themeNames.dark) {
          <img src="assets/images/logos/AnyManager/White/AnyManager_white.svg" width="206" height="40" alt="anymanager-logo" />
        }
      </div>
      <div class="content-wrapper">
        <ng-template [ngTemplateOutlet]="baseErrorContent"></ng-template>
      </div>
    </div>
  </div>
} @else {
  <div id="error-404" class="card-container error-pages" fxLayout="column" fxLayoutAlign="center center">
    <mat-icon [svgIcon]="'warning'" class="warning-icon"></mat-icon>
    <div class="message">404 {{ 'pages:ERRORS.ERROR_404.TITLE' | i18next }}</div>
    <div class="sub-message">
      {{ 'pages:ERRORS.ERROR_404.CONTENT' | i18next }}
    </div>
    <button [routerLink]="user ? '/apps/dashboard' : ''" anyButton themeColor="primary">
      {{ 'pages:ERRORS.HOME_BUTTON' | i18next }}
    </button>
  </div>
}

<ng-template #baseErrorContent>
  <div id="error-404" class="card-container error-pages" fxLayout="column" fxLayoutAlign="center center">
    <mat-icon [svgIcon]="'warning'" class="warning-icon"></mat-icon>
    <div class="message">404 {{ 'pages:ERRORS.ERROR_404.TITLE' | i18next }}</div>
    <div class="sub-message">
      {{ 'pages:ERRORS.ERROR_404.CONTENT' | i18next }}
    </div>
    <button [routerLink]="user ? '/apps/dashboard' : ''" anyButton themeColor="primary">
      {{ 'pages:ERRORS.HOME_BUTTON' | i18next }}
    </button>
  </div>
</ng-template>
